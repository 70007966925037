/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-multi-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { Container, Icon, Segment } from 'semantic-ui-react'
import { isNil } from 'ramda'
// Local
import { LIST_PAGE_SIZE, LIST_MAX_PAGES, FORMAT } from '../../constant'
import { loadPatients } from '../../action/patient'
import { loadEncounters, updateList } from '../../action/encounter'
import SubHeader from '../common/subHeader'
import Table from '../common/table'
import Filter, { generateQueryFilters } from '../common/filter'
import { patientMrn } from '../common/patientUtils'
import {
  allowPatientTabSchedulingCdi,
  allowPatientTabSchedulingProvider,
  isCdi
} from '../common/currentUserUtils'
import './index.css'
import {
  cursorClass,
  defaultSort,
  filterMetaData,
  INITIAL_PAGES_DISPLAYED
} from './utils'
import PatientScheduleModal from './patientScheduleModal'
import { semiColonsToLineBreaks } from '../v2/utils/utils'
import { basename } from 'path'

const getEmptyStringFromNull = value => isNil(value) ? '' : value

/** Map patient data into the proper display format. */
const generateTableData = patients =>
  patients.map(patient => {
    const mrn = patientMrn(patient, patient.tenantId)
    const result = {
      id: patient.id,
      name: `${patient.firstName} ${patient.lastName}`,
      birthDate: isNil(patient.birthDate)
        ? ''
        : format(patient.birthDate, FORMAT.DATE),
      mrn: semiColonsToLineBreaks(mrn),
      email: getEmptyStringFromNull(patient.personalEmail),
      phone: getEmptyStringFromNull(patient.homePhone),
      insurance: getEmptyStringFromNull(patient.insurance),
      prevEncounter: isNil(patient.prevEncounter) ? null : patient.prevEncounter,
      proposedEncounter: isNil(patient.proposedEncounter) ? null : patient.proposedEncounter,
      nextEncounter: isNil(patient.nextEncounter) ? null : patient.nextEncounter,
      meta: {
        className: cursorClass
      }
    }
    return result
  })

/** Map patient data into the proper display format. */
// function generateTableData(patients: Patient[]): PatientRecordForDisplay[] {
//   if (!patients) return []
//   const tableList = patients.map((patient: Patient) => ({
//     id: patient.id,
//     name: `${patient.firstName} ${patient.lastName}`,
//     birthDate: isNil(patient.birthDate)
//       ? ''
//       : format(patient.birthDate, FORMAT.DATE),
//     mrn: patientMrn(patient, patient.tenantId),
//     email: patient.personalEmail,
//     phone: patient.homePhone,
//     insurance: patient.insurance,
//     appointmentType: patient.appointmentType,
//     appointmentDate: patient.appointmentDate,
//     providerName: patient.providerName,
//     meta: {
//       className: cursorClass
//     }
//   }))
//   // return tableList
//   return [
//     {
//       id: 'foo',
//       name: 'John Anderson',
//       birthDate: 'new Date()',
//       mrn: '12345',
//       email: 'td@d.com',
//       phone: '(561) 706-2541',
//       insurance: 'something',
//       appointmentType: 'another thing',
//       appointmentDate: 'new Date()',
//       providerName: 'The Provider',
//       // appointment: allowPatientTabScheduling(user) ? <span>Schedule</span> : <></>,
//       meta: {
//         className: cursorClass
//       }
//     }
//   ]
// }

const shouldShowScheduleButton = user => Boolean (
  allowPatientTabSchedulingProvider(user) || allowPatientTabSchedulingCdi(user)
)

function computerColumns(user) {
  const baseColumns = [
    { name: 'Name', value: 'name' },
    { name: 'DOB', value: 'birthDate' },
    { name: 'MRN(s)', value: 'mrn' },
    { name: 'Email', value: 'email' },
    { name: 'Phone', value: 'phone' }
  ]

  if (shouldShowScheduleButton(user)) {
    baseColumns.push({ name: 'Upcoming Appointment', value: 'nextEncounter' })
    baseColumns.push({ name: 'Schedule New Appointment', value: 'newAppointment' })
    return baseColumns
  }
  baseColumns.push({ name: 'Appointment', value: 'nextEncounter' })
  return baseColumns
}

/** Display a list of patients */
function PatientList(props) {
  const {
    displayFilter,
    displayModal,
    user,
    history,
    dispatch,
    encounterList,
    historInReduxy
  } = props
  // Whether to displa the advanced search filters or not
  const [ shouldDisplayFilterState, setShouldshouldDisplayFilterState ] = useState(displayFilter || false)
  // Values specified in hte advanced search filters
  const [ filtersState, setFiltersState ] = useState({})
  // How many pages or results are currently displayed
  const [ pagesDisplayedState, setPagesDisplayedState ] = useState(
    INITIAL_PAGES_DISPLAYED
  )
  // Determine whether to display a specific patients encounters in a modal or not
  const [ shouldDisplayModalState, setShouldDisplayModalState ] =
    useState(displayModal || false)
  // Patients encounters to display in a modal.
  const [ patientSelectedState, setPatientSelectedState ] =
    useState(undefined)

  /** Load the default search parameters for patient data. */
  useEffect(() => {
    loadPatients({ sort: defaultSort }, dispatch)
  }, [])

  /** Toggle whether to display the advanced search or not */
  function toggleFilter() {
    setShouldshouldDisplayFilterState(!shouldDisplayFilterState)
  }

  /** Track any modifications to filter search values */
  function onChangeFilter(name, value) {
    setFiltersState(Object.assign({}, filtersState, { [name]: value }))
  }

  /** Load additional patients based on existing search criteria. */
  function onMore() {
    loadPatients({
      query: generateQueryFilters(filterMetaData, filtersState),
      limit: LIST_PAGE_SIZE,
      offset: LIST_PAGE_SIZE * pagesDisplayedState + 1,
      sort: defaultSort,
      append: true
    }, dispatch)
    setPagesDisplayedState(pagesDisplayedState + 1)
  }

  /** Initial search for patients for a given set of filters. */
  function searchWithFilter(values) {
    setPagesDisplayedState(INITIAL_PAGES_DISPLAYED)
    loadPatients({ query: values, sort: defaultSort }, dispatch)
    toggleFilter()
  }

  /**
    Select a patient.  When selected,  display set of encounters for that patient.
    NOTE we only display the first 200 encounters.  Can be improved.
  */
  function selectRecord(id) {
    setShouldDisplayModalState(true)
    setPatientSelectedState(id)
    updateList([], false)
    loadEncounters({
      query: { patientId: id },
      limit: LIST_PAGE_SIZE * LIST_MAX_PAGES,
      offset: 0,
      sort: 'start asc'
    })
  }

  /** Close patient modal. */
  function closeModal() {
    setShouldDisplayModalState(false)
    setPatientSelectedState(undefined)
  }

  // const { pagesDisplayed, filters, displayModal } = state
  const tableData = generateTableData(props.list)
  const filterButton = (
    <Icon
      name="filter"
      className="pointer"
      size="large"
      onClick={ toggleFilter }
    />
  )
  let displayMaxRecords
  let shouldDisplayMore = (displayMaxRecords = false)
  if (tableData.length > LIST_PAGE_SIZE * pagesDisplayedState) {
    shouldDisplayMore = pagesDisplayedState < LIST_MAX_PAGES
    displayMaxRecords = pagesDisplayedState >= LIST_MAX_PAGES
    tableData.pop()
  }
  return (
    <div className="header-padding">
      <Container>
        <SubHeader left="Patients" right={ filterButton } />
        { shouldDisplayFilterState && (
          <Filter
            initialValues={ filtersState }
            onChange={ onChangeFilter }
            submit={ searchWithFilter }
            filters={ filterMetaData }
          />
        ) }
        <Segment attached className="no-margin">
          <Table
            computerColumns={ computerColumns(user) }
            mobileColumns={ [
              { value: 'name', width: '11' },
              { value: 'birthDate', width: '5' }
            ] }
            data={ tableData }
            onClick={ selectRecord }
            more={ shouldDisplayMore }
            // max={ displayMaxRecords }
            onMore={ onMore }
          />
        </Segment>
        { displayModal && (
          <PatientScheduleModal
            list={ encounterList }
            historInReduxy={ historInReduxy }
            closeModal={ closeModal }
          />
        ) }
      </Container>
    </div>
  )
}

export default connect(state => ({
  user: state.user,
  list: state.patient.list,
  encounterList: state.encounter.list
}))(PatientList)
