import filter from 'lodash/filter'
import join from 'lodash/join'
import map from 'lodash/map'
import { addDays, format } from 'date-fns'
import { FORMAT, LIST_PAGE_SIZE } from '../constant'


/**
 * @param s Query parameter
 * @return True if the query parameter looks like a range
 */
export function isRangeParameter(s: any) {
  return s.trim().startsWith('[') && s.trim().endsWith(']')
}

/**
 * @param s Query parameter
 * @return True if the query parameter looks like a date range
 */
export function isDateRangeParameter(s: any) {
  return isRangeParameter(s) && s.toUpperCase().includes(' TO ')
}

/**
 * Provides helpers for creating search request parameters
 * @param values Search query parameter values
 * @param limit Search request limit amount
 * @param offset Search request offset amount
 * @param sort Search request sort direction
 * @param queryOverride Override values with query override string
 * @return Search query parameter string
 */
// eslint-disable-next-line max-len

export function searchGenerator(
  values: Record<string, string> | null,
  limit: number | undefined,
  offset: number | undefined,
  sort: string | null,
  queryOverride: unknown | undefined
) {
  if (!limit) {
    limit = LIST_PAGE_SIZE + 1
  }
  if (!offset) {
    offset = 0
  }

  // transform a key value pair into a search argument
  const getSearchArg = (key: string, value: any) => {
    if ( !value ) return null

    if (value instanceof Date) {
      const formattedStart = format(value, FORMAT.API_DATE)
      const formattedEnd = format(addDays(value, 1), FORMAT.API_DATE)
      return `${key}:[${formattedStart} TO ${formattedEnd}]`
    }

    if ( Array.isArray(value) ) {
      const values = value.map(v => v.trim())
      return `${key}:[${values.join(' ')}]`
    }

    if ( isDateRangeParameter(value) ) {
      return `${key}:${value.trim()}`
    }

    return `${key}:${value.trim()}`
  }

  const params = {
    sort: sort || '',
    limit,
    offset,
    q: {}
  }

  if (queryOverride) {
    // prefer the query override, if provided
    params.q = queryOverride
  }

  if (!queryOverride && values) {
    // transform query values into query arguments
    const args = map(values, (value, key) => getSearchArg(key, value))
    // filter out any empty query arguments
    const filtered = filter(args, arg => !!(arg && arg.trim().length > 0))
    // transform query arguments into comma delimited string
    const query = join(filtered, ',')

    params.q = query
  }

  return params
}