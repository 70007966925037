import {
  UPDATE_LIST,
  APPEND_LIST,
  UPDATE_SELECTED,
  UPDATE_PDF_LIST,
  CLEAR_PDF_LIST,
  PDF_READY,
  UPDATE_RAF,
  UPDATE_PAGES_SEEN
} from "../reducer/encounter";
import { API_URL, PAGES, REVIEW } from "../constant";
import { addMinutes, subMinutes, parse } from "date-fns";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import { api } from "./api";
import { searchGenerator } from "./search";
import { push } from "react-router-redux";
import { clearSelected as clearSelectedPatient } from "./patient";
import { auditDailyHuddleRead } from "./audit";
import { updateEncounterConditions, updatePreviousConditions } from "./condition";

export function clearSelected() {
  return {
    type: UPDATE_SELECTED,
    payload: {}
  };
}

export function updateList(list, onSchedule, physicianDay) {
  return {
    type: UPDATE_LIST,
    payload: { list: list, onSchedule: onSchedule, physicianDay: physicianDay }
  };
}

export function appendList(list) {
  return {
    type: APPEND_LIST,
    payload: list
  };
}

export function updateSelected(body) {
  return {
    type: UPDATE_SELECTED,
    payload: body
  };
}

export function updatePdfList(body) {
  return {
    type: UPDATE_PDF_LIST,
    payload: body
  };
}

export function clearPdfList() {
  return {
    type: CLEAR_PDF_LIST
  };
}

export function setPdfReady(ready) {
  return {
    type: PDF_READY,
    payload: ready
  };
}

export function updateRafValues(raf) {
  return {
    type: UPDATE_RAF,
    payload: raf
  };
}

export function clearAndLoadSelected(id) {
  return dispatch => {
    dispatch(clearSelected());
    dispatch(clearSelectedPatient());
    dispatch(loadSelected(id));
  };
}

export function loadSelected(id) {
  return dispatch => {
    return api.get(API_URL.GET_ENCOUNTER_DETAIL_BY_ID + id).then(({ data }) => {
      dispatch(updatePreviousConditions(data.previous));
      dispatch(updateEncounterConditions(data.conditions));
      dispatch(updateSelected(data));
    });
  };
}

export function loadPdfList(list) {
  if (list && list.length > 0) {
    var encounterId = list.shift();
    return dispatch => {
      return api.get(API_URL.GET_ENCOUNTER_DETAIL_BY_ID + encounterId).then(({ data }) => {
        dispatch(updatePdfList(data));
        dispatch(loadPdfList(list));
      });
    };
  } else {
    return dispatch => {
      auditDailyHuddleRead();
      dispatch(setPdfReady(true));
    };
  }
}

export function loadNextCdiEncounter(userId) {
  return dispatch => {
    var params = searchGenerator({ reviewCode: "OPEN", encounterStatus: "PLANNED" },
      1, 0, "start desc");
    return api.get(API_URL.ENCOUNTER_SEARCH, { params: params }).then(({ data }) => {
      if (data[0]) {
        dispatch(push(PAGES.ENCOUNTER_DETAIL + data[0].id));
        //Page change is not forcing a page update.  Handling here also
        dispatch(loadSelected(data[0].id));
      } else {
        // There are no more to load, go back to the main search page.
        dispatch(push(PAGES.HOME));
      }
    });
  };
}

export function loadEncounters(props) {
  const ON_SCHEDULE_BUFFER = 30;
  var onSchedule = encounters => {
    if ( !encounters || encounters.length === 0 ) return false;
    // Identifies the highest/lowest encounter, pulls the start date and applies buffer.
    var lowest = subMinutes(parse(minBy(encounters, e => parse(e.start)).start), ON_SCHEDULE_BUFFER);
    var highest = addMinutes(parse(maxBy(encounters, e => parse(e.start)).start), ON_SCHEDULE_BUFFER);
    return new Date() < highest && new Date() > lowest;
  };

  return dispatch => {
    var params = searchGenerator(props.query, props.limit, props.offset, props.sort);
    const start = props.query && props.query.start ? props.query.start : undefined;
    return api.get(API_URL.ENCOUNTER_SEARCH, { params: params }).then(({ data }) => {
      if (!props.append) {
        dispatch(updateList(data, props.checkOnSchedule ? onSchedule(data) : false, start));
      } else {
        dispatch(appendList(data, props.checkOnSchedule ? onSchedule(data) : false, start));
      }
    });
  };
}

export function loadCoderEncounters(props) {
  return dispatch => {
    var query = props.query || {};
    let reviewCode = [REVIEW.CODE.READY_FOR_PHYSICIAN];
    switch ( props.worklistView ) {
      case 'ALL':
        reviewCode = [
          REVIEW.CODE.READY_FOR_PHYSICIAN,
          REVIEW.CODE.COMPLETED,
          REVIEW.CODE.READY_FOR_CODER_QA
        ];
        break;
      case REVIEW.CODE.COMPLETED:
        reviewCode = [REVIEW.CODE.COMPLETED];
        break;
      case REVIEW.CODE.READY_FOR_CODER_QA:
        reviewCode = [REVIEW.CODE.READY_FOR_CODER_QA];
        break;
      default:
        reviewCode = [REVIEW.CODE.READY_FOR_PHYSICIAN];
    }
    query["reviewCode"] = reviewCode;
    var params = searchGenerator(query, props.limit, props.offset, props.sort);
    return api.get(API_URL.ENCOUNTER_SEARCH, { params: params }).then(({ data }) => {
      if (!props.append) {
        dispatch(updateList(data));
      } else {
        dispatch(appendList(data));
      }
    });
  };
}

export function updateRaf(patientId, encounterId) {
  return dispatch => {
    return api.put(API_URL.RAF_UPDATE + "/patient/" + patientId + "/encounter/" + encounterId).then(({ data }) => {
      dispatch(updateRafValues(data));
    });
  };
}

export function setEncounterStatus(encounterId, status) {
  return dispatch => {
    const url = API_URL.UPDATE_ENCOUNTER_STATUS + '/' + encounterId + '/' + status;
    return api.put(url, {}).then(({ data }) => {
      dispatch(loadSelected(encounterId));
    });
  };
}

export function addEncounterNote(encounterId, note) {
  return dispatch => {
    const url = API_URL.CREATE_ENCOUNTER_NOTE;
    return api.post(url, note).then(({ data }) => {
      dispatch(loadSelected(encounterId));
    });
  };
}

// action creator
export function updatePagesSeen(pagesSeen) {
  return {
    type: UPDATE_PAGES_SEEN,
    payload: pagesSeen
  }
}


