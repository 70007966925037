import { User, WorklistFilters } from '../authenticated/types'
import { patientOrgFilter, patientRegionFilter } from './currentUserUtils'

/**
 * Provides user worklist filter management utilities
 *
 * The worklist filters are a mix of defaults, set in user properties, and then
 * overrides provided by user actions. For example, a user property can specify
 * default regions and then the user can select a specific region from the
 * region filter options.
 */

/**
 * @return Worklist filters all initialized to null
 */
export const emptyWorklistFilters = (): WorklistFilters => ({
  start: null,
  firstName: null,
  lastName: null,
  birthDate: null,
  patientOrgId: null,
  appointmentType: null,
  practitionerId: null,
  view: null,
  encounterSort: null,
  patientRegion: null,
  vbcProgram: null,
  defaultPatientRegion: null,
  defaultPatientOrgId: null,
  mrn: null,
  potentialConditionCount: null,
  specialty: null
})

/**
 * Initialize empty worklist filters with defaults
 * @param currentUser Current user entity
 * @return Initialized worklist filters
 */
export function initWorklistFilters(currentUser: User | any) {
  const filters = emptyWorklistFilters()
  filters.defaultPatientRegion = patientRegionFilter(currentUser as User)
  filters.defaultPatientOrgId = patientOrgFilter(currentUser as User)
  return filters
}

/**
 * Update worklist filters. Send all filters that are currently enabled.
 * Required default filters are set
 * @param currentFilters Current state of filters
 * @param updateFilters Filter update requests
 * @return Filters with requested updates applied
 */
export function updateWorklistFilters(
  currentUser: User | any,
  updateFilters: WorklistFilters
): WorklistFilters {
  const filters = initWorklistFilters(currentUser)
  const {
    start,
    firstName,
    lastName,
    birthDate,
    patientOrgId,
    appointmentType,
    practitionerId,
    view,
    encounterSort,
    patientRegion,
    vbcProgram,
    mrn,
    potentialConditionCount,
    specialty
  } = updateFilters

  return {
    ...filters,
    start,
    firstName,
    lastName,
    birthDate,
    patientOrgId,
    appointmentType,
    practitionerId,
    view,
    encounterSort,
    patientRegion,
    vbcProgram,
    mrn,
    potentialConditionCount,
    specialty
  }
}
