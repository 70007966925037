/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-multi-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-rest-params */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Icon, Popup, Segment } from 'semantic-ui-react'
import concat from 'lodash/concat'
import { format } from 'date-fns'
import { API_URL, FORMAT, LIST_PAGE_SIZE, LIST_MAX_PAGES, PAGES, ROLES } from '../../constant'
import Filter from '../common/filter'
import SubHeader from '../common/subHeader'
import Table from '../common/table'
import { patientMrn } from '../common/patientUtils'
import { semiColonsToLineBreaks } from '../v2/utils/utils'
import { api } from '../../action/api'
import { updatePagesSeen } from '../../action/encounter'
import { searchGenerator } from '../../action/search'
import { updateUserAppointmentlistFilters } from '../../action/user'

const defaultSort = 'start desc'

// TODO - sboles - This is actually showing the appointment list.
// - Move this into its own top level module and name the file something obvious

/** Display a list of appointments, but they are called encounters in our api. */
export class Appointments extends Component {
  /** Prepare components initial state */
  constructor() {
    super(...arguments)
    const encounterSort = defaultSort

    // break down the filter criteria that came from global/Redux state
    const query = this.buildQuery(this.props.appointmentlistFilters)
    const showFilter = Object.values(query).some(e => Boolean(e))

    // setup our component state
    this.state = {
      showFilter,
      encounterSort,
      pagesDisplayed: this.props.pagesSeen,
      queryFilters: this.filterInitialValues(query)
    }
  }

  /** Load the default search parameters for encounter data. */
  componentDidMount() {
    const offset = 0
    const query = this.buildQuery(this.state.queryFilters)
    this.fetchEncounters(offset, query, this.state.pagesDisplayed)
  }

  /** Load a selected appointment */
  selectRecord = (id, e) => {
    this.props.history.push(`${PAGES.ENCOUNTER_DETAIL}${id}`)
  }

  /** Toggle whether to display the advanced search or not */
  toggleFilter = (e, iconProps) => {
    this.setState({ showFilter: !this.state.showFilter })
  }

  /** Initial search for encounters for a given set of filters. */
  searchWithFilter = query => {
    // query comes directly from the Filter component
    this.props.updateUserAppointmentlistFilters(query)
    const offset = 0
    this.fetchEncounters(offset, query, this.state.pagesDisplayed)
  }

  /** Build query object from component state or global state */
  buildQuery = filterObj => {
    const { mrn,
      start,
      birthDate,
      firstName,
      lastName,
      practitionerId } = filterObj

    // convert strings to Date objects here
    return {
      mrn: this.elseNull(mrn),
      start: start ? new Date(start) : null,
      birthDate: birthDate ? new Date(birthDate) : null,
      firstName: firstName ? `${firstName.replaceAll('%', '')  }%` : null,
      lastName: lastName ? `${lastName.replaceAll('%', '')  }%` : null,
      practitionerId: this.elseNull(practitionerId)
    }
  }

  /** Get the values we want to show in the filter component. */
  filterInitialValues = queryFilters => {
    // may have a full blown date object here, want to show a short date in the filter component
    // Do the opposite of buildQuery, and get back to a string
    const datefmt = s => s ? format(new Date(s), FORMAT.DATE) : null
    const textfmt = t => t ? t.replaceAll('%', '') : null
    return {
      ...queryFilters,
      firstName: textfmt(queryFilters.firstName),
      lastName: textfmt(queryFilters.lastName),
      start: datefmt(queryFilters.start),
      birthDate: datefmt(queryFilters.birthDate)
    }
  }

  /** Utility function */
  formatEncounterDate = (startDt, status) => status === 'CANCELLED'
    ? <Popup content="Cancelled" mouseEnterDelay={ 1000 } trigger={ <s>{ format(startDt, FORMAT.DATE_TIME) }</s> } />
    : <span>{ format(startDt, FORMAT.DATE_TIME) }</span>


  /** Map encounter data into the proper display format. */
  generateTableData = (encounters, props) => {
    if (!encounters) return []
    return encounters.map(record => {
      const { patient } = record
      const mrn = patientMrn(patient, patient.tenantId)
      return {
        id: record.id,
        time: this.formatEncounterDate(record.start, record.status),
        name: `${patient.firstName} ${patient.lastName}`,
        name_icon: (props.type === ROLES.CDI &&
          record.review &&
          record.review.reviewCode === 'LOCKED') ? <Icon name="lock" /> : null,
        dob: format(patient.birthDate, FORMAT.DATE),
        mrn: semiColonsToLineBreaks(mrn),
        appointmentType: record.appointmentType,
        nameMobile:
          `${patient.firstName
          } ${patient.lastName
          } ( ${format(patient.birthDate, FORMAT.DATE)
          } )`,
        practitioner: `${record.practitioner.firstName} ${record.practitioner.lastName}`,
        meta: {
          className: 'cursor-pointer'
        }
      }
    })
  }

  /** Fetch encounters based on filter criteria, if any. This is the workhorse method. */
  fetchEncounters = (offset, query, pagesSeen) => {
    const searchQuery = query || {}
    const sort = this.state.encounterSort
    const url = API_URL.ENCOUNTER_SEARCH
    const limit = offset===0 ? (LIST_PAGE_SIZE * pagesSeen) + 1 : LIST_PAGE_SIZE
    const params = searchGenerator(searchQuery, limit, offset, sort)

    api.get(url, { params }).then(({ data }) => {
      const results = offset === 0 ? data : concat(this.state.encounters, data)
      this.setState({
        encounters: results,
        pagesDisplayed: pagesSeen,
        encounterSort: sort,
        queryFilters: searchQuery
      })
    })
  }

  /** Load additional encounters based on existing search criteria. */
  onMore = filters => {
    const query = this.buildQuery(this.state.queryFilters)
    const offset = LIST_PAGE_SIZE * this.state.pagesDisplayed
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const newPagesDisplayed = this.state.pagesDisplayed + 1

    this.fetchEncounters(offset, query, newPagesDisplayed)
    // update the Redux global state
    this.props.updatePagesSeen(newPagesDisplayed)
  }

  /** Utility function for readability */
  elseNull = variable => variable || null

  getFilterMetadata = () => {
    const metadata = [
      [
        { placeholder: 'Patient MRN', name: 'mrn', width: 6, like: false },
        { placeholder: 'First Name', name: 'firstName', width: 5, like: true },
        { placeholder: 'Last Name', name: 'lastName', width: 5, like: true }
      ],
      [
        { placeholder: 'Date of Birth', name: 'birthDate', width: 6, date: true },
        { placeholder: 'Appointment Date', name: 'start', width: 5, date: true },
        { placeholder: 'Practitioner', name: 'practitionerId', width: 5, practitionerSelect: true }
      ]
    ]
    return metadata
  }

  render() {
    const { encounters, pagesDisplayed, showFilter } = this.state
    const tableData = this.generateTableData(encounters, this.props)

    let displayMore; let displayMaxRecords
    displayMore = displayMaxRecords = false
    if (tableData.length > LIST_PAGE_SIZE * pagesDisplayed) {
      displayMore = pagesDisplayed < LIST_MAX_PAGES
      displayMaxRecords = pagesDisplayed >= LIST_MAX_PAGES
      tableData.pop()
    }
    return (
      <div className="header-padding">
        <Container>
          <SubHeader
            left="Appointments"
            right={ <Icon name="filter" size="large" className="pointer" onClick={ this.toggleFilter } /> }
          />
          { showFilter && (
            <Filter
              submit={ this.searchWithFilter }
              filters={ this.getFilterMetadata() }
              initialValues={ this.state.queryFilters }
            />
          ) }
          <Segment attached className="no-margin">
            <Table
              computerColumns={ [
                { name: 'Time', value: 'time' },
                { name: 'Type', value: 'appointmentType' },
                { name: 'Name', value: 'name' },
                { name: 'DOB', value: 'dob' },
                { name: 'MRN(s)', value: 'mrn' },
                { name: 'Practitioner', value: 'practitioner' }
              ] }
              mobileColumns={ [{ value: 'time', width: '4' }, { value: 'nameMobile', width: '12' }] }
              data={ tableData }
              onClick={ this.selectRecord }
              more={ displayMore }
              max={ displayMaxRecords }
              onMore={ this.onMore }
            />
          </Segment>
        </Container>
      </div>
    )
  }
}

// Redux selector
const mapStateToProps = state => ({
  type: state.user.type,
  appointmentlistFilters: state.user.appointmentlistFilters,
  pagesSeen: state.encounter.pagesSeen
})

// Use react-redux connect to wrap updateUserAppointmentlistFilters & updatePagesSeen
export default connect(
  mapStateToProps,
  {
    updateUserAppointmentlistFilters,
    updatePagesSeen
  }
)(Appointments)
