import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import * as config from "../../../config";
import { API_URL } from "../../../constant";

/**
 * @param patientId Patient ID
 * @param clazz Optional class name to apply to image
 */
class PatientProfile extends Component {

  render() {
    const url = config.BASE_URL + API_URL.PATIENT_PROFILE + this.props.patientId;
    const className = this.props.clazz ? this.props.clazz : "";

    return (
      <Image src={url} className={className} />
    );
  }
}

export default PatientProfile;
