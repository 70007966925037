import filter from "lodash/filter";
import find from "lodash/find";
import { ROLES } from "../../constant";

/*******************************************************************************
 * Provides utilities for managing user roles
 */

/**
 * The UI recognizes some roles as primary user roles. These are roles that
 * the UI knows how to act on.
 * @param role User role
 * @return True if the role is recognized as a primary user role
 */
export function isPrimaryRole(role) {
  return role.name === ROLES.CDI ||
    role.name === ROLES.CLINICAL_ADMIN ||
    role.name === ROLES.CODER ||
    role.name === ROLES.PHYSICIAN;
}

/**
 * A user can have roles that the UI either does not recognize or does not
 * know how to act on. These are supplemental roles
 * @param role User role
 * @return True if the role is recognized as a supplemental role
 */
export function isSupplementalRole(role) {
  return role.name === ROLES.CDI_QA_MANAGER ||
    role.name === ROLES.CODER_QA_MANAGER;
}

/**
 * @param roles List of user roles
 * @return Roles filtered to include only primary roles
 */
export function withPrimaryRoles(roles) {
  return filter(roles, function(role) {
    return isPrimaryRole(role);
  });
}

/**
 * @param roles List of user roles (strings or role entities)
 * @param matchRole Role to match (string or role entity)
 * @return True if list of roles includes match
 */
export function hasRole(roles, matchRole) {
  const result = find(roles, function(r) {
    if ( typeof r === "string" ) {
      if ( typeof matchRole === "string" ) {
        return r === matchRole;
      } else {
        return r === matchRole.name;
      }
    } else {
      if ( typeof matchRole === "string" ) {
        return r.name === matchRole;
      } else {
        return r.name === matchRole.name;
      }
    }
  })
  return result ? true : false;
}

/**
 * @param userRoles List of user roles
 * @param matchRoles List of roles to match
 * @return True if user has any match role
 */
export function hasAnyRole(userRoles, matchRoles) {
  return matchRoles.map((role) => {
    return hasRole(userRoles, role)
  }).includes(true);
}

/**
 * @param roles List of user roles
 * @return True if roles include Coder QA manager
 */
export function isCoderQaManager(roles) {
  return hasRole(roles, { name: ROLES.CODER_QA_MANAGER });
}

/**
 * @param roles List of user roles
 * @return True if roles include CDI QA manager
 */
export function isCdiQaManager(roles) {
  return hasRole(roles, { name: ROLES.CDI_QA_MANAGER });
}

export function isCdi(roles) {
  return hasRole(roles, { name: ROLES.CDI });
}
