import { parse } from 'date-fns'
import { isNil, pathOr, reject } from 'ramda'
import { filter, includes, orderBy } from 'lodash'
// Local
import {
  ConditionStatus,
  ConditionType,
  StatusCode,
  TargetSourceCode
} from '../patient/patient'
import {
  ButtonOptions,
  Encounter,
  EncounterConditionCoderTagsRow,
  EncounterConditionDisplay,
  EncounterConditionDisplayModified,
  EncounterConditionRuleRow,
  EncounterNoteRow,
  RuleInputJson,
  User
} from '../authenticated/types'
import {
  CODER,
  CODER_ON_CLAIM_STATUSES,
  CODER_REPORTABLE_STATUSES,
  CONDITION,
  NOTE,
  ROLES,
  RULE_TYPE_CODES,
  RULE_FILTER_DYNAMIC_TYPES_BY_ID,
  TENANT_PROPERTY
} from '../../constant'
import { propertyValue } from './tenantPropertyUtilsTwo'
import {
  encounterConditionDisplayNlpInsights,
  knownConditionDismissEnabled
} from './currentUserUtils'
import { formatIcd10Code } from './formatUtils'
import { isExpired } from './dateTimeUtils'

type ConditionToBoolean = (x: EncounterConditionDisplayModified) => boolean
type ConditionToBooleanOriginal = (x: EncounterConditionDisplay) => boolean
type ConditionsToBoolean = (x: EncounterConditionDisplayModified[]) => boolean

type Condition = {
  shouldRemoveFromUI: ConditionToBooleanOriginal
  isPotential: ConditionToBooleanOriginal
  isKnown: ConditionToBooleanOriginal
  isActivity: ConditionToBoolean
  isExternal: ConditionToBooleanOriginal
  hasPractitionerResponseCode: ConditionToBoolean
  isConditionAddressed: ConditionToBooleanOriginal
  isKnownAddressed: ConditionToBooleanOriginal
  hasCdiTags: ConditionToBooleanOriginal
  hasCoderTags: ConditionToBooleanOriginal
  isClosed: ConditionToBooleanOriginal
  isClosedActivity: ConditionToBooleanOriginal
  hasQuery: ConditionToBooleanOriginal
  hasPractitionerTags: ConditionToBooleanOriginal
  isOpen: ConditionToBooleanOriginal
  isOpenOrAddressed: ConditionToBooleanOriginal
  isKnownOrPotential: ConditionToBooleanOriginal
  isKnownOrPotentialModified: ConditionToBoolean
  isNotReportable: ConditionToBooleanOriginal
  isOnClaim: ConditionToBooleanOriginal
  isReportable: ConditionToBooleanOriginal
  getPractitionerResponseCode: (x: EncounterConditionDisplay) => string | null
  hasPractitionerTagOptions: (
    x: EncounterConditionDisplayModified,
    tagOptions: ButtonOptions[] | null
  ) => boolean
}

const hasExternalCondition = (rule: EncounterConditionRuleRow): boolean =>
  rule.targetSourceCode === TargetSourceCode.External

const isHidden = (c: EncounterConditionDisplay): boolean =>
  c.statusCode === StatusCode.Hidden

const isDismissed = (c: EncounterConditionDisplay): boolean =>
  c.conditionCode === ConditionStatus.Dismissed

const isRemoved = (c: EncounterConditionDisplay): boolean =>
  c.conditionCode === ConditionStatus.Removed

export const condition: Condition = {
  shouldRemoveFromUI: (c: EncounterConditionDisplay): boolean =>
    isHidden(c) || isDismissed(c) || isRemoved(c),
  isActivity: (c: EncounterConditionDisplayModified): boolean =>
    c.conditionStatus === ConditionType.Activity,
  isKnown: (c: EncounterConditionDisplay): boolean =>
    c.conditionStatus === ConditionType.Known,
  isPotential: (c: EncounterConditionDisplay): boolean =>
    c.conditionStatus === ConditionType.Potential,
  isExternal: (c: EncounterConditionDisplay): boolean => {
    const rules = c && c.rules ? c.rules : []
    return !!rules.length && rules.some(hasExternalCondition)
  },
  hasCdiTags: (c: EncounterConditionDisplay): boolean =>
    !!c.cdiTags &&
    (!!c.cdiTags.netNewStatusCode ||
      !!c.cdiTags.knownPotentialStatusCode ||
      !!c.cdiTags.expectedDiagnosis),
  hasCoderTags: (c: EncounterConditionDisplay): boolean => {
    if (!c.coderTags) return false
    return (
      !!c.coderTags.activityMetStatusCode ||
      !!c.coderTags.onClaimStatusCode ||
      !!c.coderTags.reportableHeaderCode ||
      !!c.coderTags.reportableStatusCode ||
      !!c.coderTags.reportableReason ||
      !!c.coderTags.queryStatusCode
    )
  },
  isClosed: (c: EncounterConditionDisplay): boolean =>
    c.conditionCode === CONDITION.CODE.CLOSED,
  isClosedActivity: (c: EncounterConditionDisplay): boolean =>
    c && c.conditionStatus === CONDITION.TYPE.ACTIVITY && condition.isClosed(c),
  hasQuery: (c: EncounterConditionDisplay): boolean =>
    !!c.coderTags &&
    !!c.coderTags.queryStatusCode &&
    c.coderTags.queryStatusCode === CONDITION.QUERY_STATUS.QUERY_OUTSTANDING,
  hasPractitionerTags: (c: EncounterConditionDisplay): boolean => {
    if (!c.practitionerTags) return false
    return (
      !!c.practitionerTags &&
      !!c.practitionerTags.practitionerResponseCode &&
      c.practitionerTags.practitionerResponseCode !==
        CONDITION.PRACTITIONER_RESPONSE.NONE
    )
  },
  isOpen: (c: EncounterConditionDisplay): boolean =>
    c && c.conditionCode === CONDITION.CODE.OPEN,
  isOpenOrAddressed: (c: EncounterConditionDisplay): boolean =>
    condition.isOpen(c) || c.conditionCode === CONDITION.CODE.ADDRESSED,
  isKnownOrPotential: (c: EncounterConditionDisplay): boolean =>
    condition.isKnown(c) || condition.isPotential(c),
  isKnownOrPotentialModified: (c: EncounterConditionDisplayModified): boolean =>
    c.isKnown || c.isPotential,
  isNotReportable: (c: EncounterConditionDisplay): boolean => {
    if (!c.coderTags) return false
    return (
      condition.isKnownOrPotential(c) &&
      condition.isOpenOrAddressed(c) &&
      c.coderTags &&
      c.coderTags.reportableStatusCode ===
        CONDITION.REPORTABLE_STATUS.NOT_REPORTABLE
    )
  },
  isOnClaim: (c: EncounterConditionDisplay): boolean => {
    if (!c.coderTags) return false
    return (
      condition.isKnownOrPotential(c) &&
      condition.isOpenOrAddressed(c) &&
      c.coderTags &&
      c.coderTags.onClaimStatusCode === CONDITION.ON_CLAIM_STATUS.ON_CLAIM
    )
  },
  isReportable: (c: EncounterConditionDisplay): boolean => {
    if (!c.coderTags) return false
    return (
      condition.isKnownOrPotential(c) &&
      condition.isOpenOrAddressed(c) &&
      c.coderTags &&
      c.coderTags.reportableStatusCode ===
        CONDITION.REPORTABLE_STATUS.REPORTABLE
    )
  },
  hasPractitionerResponseCode: (
    c: EncounterConditionDisplayModified
  ): boolean =>
    pathOr(false, [ 'practitionerTags', 'practitionerResponseCode' ], c),
  getPractitionerResponseCode: (c: EncounterConditionDisplay): string | null =>
    pathOr(null, [ 'practitionerTags', 'practitionerResponseCode' ], c),
  isConditionAddressed: (c: EncounterConditionDisplay): boolean => {
    if (c.addressedDate && c.encounter && c.encounter.startDate) {
      const addressedYear = parse(c.addressedDate).getFullYear()
      const encounterYear = parse(c.encounter.startDate).getFullYear()
      return addressedYear === encounterYear
    }
    return false
  },
  isKnownAddressed: (c: EncounterConditionDisplay): boolean => {
    // rules engine (checkMark) should trump, but fall back to original logic if field is null
    if (c.checkMark) {
        // only return true if this is GREEN_CHECK. all others return false
        return (c.checkMark === "GREEN_CHECK")
    } else {
        return (condition.isKnown(c) && condition.isConditionAddressed(c))
    }
  },
  hasPractitionerTagOptions: (
    c: EncounterConditionDisplayModified,
    tagOptions: ButtonOptions[] | null
  ): boolean => {
    if (tagOptions === null) return false
    if (condition.isActivity(c) && !!tagOptions.length) return true
    if (c.isKnown && !!tagOptions.length) return true
    if (c.isPotential && !!tagOptions.length) return true
    if (c.isExternal && !!tagOptions.length) return true
    return false
  }
}

type Conditions = {
  hasExternal: ConditionsToBoolean
  hasActivity: ConditionsToBoolean
  hasKnown: ConditionsToBoolean
  hasPotential: ConditionsToBoolean
  areAllPotential: ConditionsToBoolean
  hasKnownOrPotential: ConditionsToBoolean
  withNotAddressedConditions: (
    c: EncounterConditionDisplayModified[]
  ) => EncounterConditionDisplayModified[]
  getAddressedOrNull: (
    c: EncounterConditionDisplayModified[]
  ) => Record<string, any> | null
}

const isAddressed = (x: EncounterConditionDisplayModified): boolean =>
  x.isAddressed

const isKnownOrPotential = (
  c: EncounterConditionDisplay | EncounterConditionDisplayModified
): boolean =>
  c.conditionStatus === ConditionType.Known ||
  c.conditionStatus === ConditionType.Potential

export const conditions: Conditions = {
  hasExternal: (c: EncounterConditionDisplayModified[]): boolean =>
    c && !!c.filter(x => x.isExternal).length,
  hasActivity: (c: EncounterConditionDisplayModified[]): boolean =>
    c && !!c.filter(condition.isActivity).length,
  hasKnown: (c: EncounterConditionDisplayModified[]): boolean =>
    c && !!c.filter(x => x.isKnown).length,
  hasPotential: (c: EncounterConditionDisplayModified[]): boolean =>
    c && !!c.filter(x => x.isPotential).length,
  areAllPotential: (c: EncounterConditionDisplayModified[]): boolean =>
    c.every(v => v.isPotential),
  hasKnownOrPotential: (c: EncounterConditionDisplayModified[]): boolean =>
    c && !!c.filter(isKnownOrPotential).length,
  withNotAddressedConditions: (c: EncounterConditionDisplayModified[]) =>
    reject(isAddressed, c),
  getAddressedOrNull: (c: EncounterConditionDisplayModified[]) =>
    c.length
      ? {
        hccCode: null,
        hccDescription: null,
        conditions,
        addressed: false
      }
      : null
}

export function clinicalAdminDismissEnabled(currentUser: User): boolean {
  const propKey =
    TENANT_PROPERTY.ENCOUNTER_CONDITION_CLINICAL_ADMIN_DISMISS_ENABLED
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? propValue.toLowerCase() === 'true' : false
}

/**
 *
 * @param currentCondition current condition
 * @param currentUser Current Logged in User
 * @returns TRUE if condition can be dismissed
 */
export const allowConditionDismiss = (
  currentCondition: EncounterConditionDisplay,
  currentUser: User
) => {
  if (!isKnownOrPotential(currentCondition)) return false
  const shouldAllowDismiss = clinicalAdminDismissEnabled(currentUser)
  switch (currentUser.type) {
    case ROLES.PHYSICIAN:
      if (
        knownConditionDismissEnabled(currentUser) &&
        condition.isKnown(currentCondition) &&
        !condition.isConditionAddressed(currentCondition)
      ) {
        return true
      }
      if (condition.isPotential(currentCondition)) return true
      return false
    case ROLES.CLINICAL_ADMIN:
      if (
        shouldAllowDismiss &&
        knownConditionDismissEnabled(currentUser) &&
        condition.isKnown(currentCondition) &&
        !condition.isConditionAddressed(currentCondition)
      ) {
        return true
      }
      if (shouldAllowDismiss && condition.isPotential(currentCondition))
        return true
      return false
    default:
      return false
  }
}

/**
 * @return coderOnClaimStatuses in an OBJECT with the key = CONDITION.ON_CLAIM_STATUS.XXX and
 *          value is the corresponding object from the original array of objects (key-value interpolation)
 *          i.e.: {
 *                  NOT_ON_CLAIM: {key: "NOT_ON_CLAIM", text: "Not on claim"},
 *                  ON_CLAIM: {key: "ON_CLAIM", text: "On claim"}
 *                }
 */
export const coderOnClaimStatusKeyValueMap = CODER_ON_CLAIM_STATUSES.reduce(
  (statuses, currEl) => ({ ...statuses, [currEl.key]: currEl }),
  {}
)

/**
 * @return List of coder reportable headers for dropdown
 */
export const coderReportableHeaders = [
  {
    key: CONDITION.REPORTABLE_HEADER.NOT_DOCUMENTED,
    text: 'Not Documented',
    value: CONDITION.REPORTABLE_HEADER.NOT_DOCUMENTED
  },
  {
    key: CONDITION.REPORTABLE_HEADER.CODING_DETAILS,
    text: 'Coding Details',
    value: CONDITION.REPORTABLE_HEADER.CODING_DETAILS
  },
  {
    key: CONDITION.REPORTABLE_HEADER.OTHER,
    text: 'Other',
    value: CONDITION.REPORTABLE_HEADER.OTHER
  }
]

/**
 * @return List of coder reportable reasons EVER USED IN THE APP HISTORY
 */
export function coderReportableReasons() {
  return [
    {
      key: CONDITION.REPORTABLE_REASON.ACUTE_VISIT,
      text: 'Acute visit',
      value: CONDITION.REPORTABLE_REASON.ACUTE_VISIT
    },
    {
      key: CONDITION.REPORTABLE_REASON.CONDITION_NOT_DOCUMENTED_OR_ADDRESSED,
      text: 'Condition not documented or addressed',
      value: CONDITION.REPORTABLE_REASON.CONDITION_NOT_DOCUMENTED_OR_ADDRESSED
    },
    {
      key: CONDITION.REPORTABLE_REASON.CONDITION_REPORTED_RESOLVED,
      text: 'Condition reported as resolved',
      value: CONDITION.REPORTABLE_REASON.CONDITION_REPORTED_RESOLVED
    },
    {
      key: CONDITION.REPORTABLE_REASON.LACK_OF_HISTORICAL_DATA,
      text: 'Lack of historical data/medical records to validate condition',
      value: CONDITION.REPORTABLE_REASON.LACK_OF_HISTORICAL_DATA
    },
    {
      key: CONDITION.REPORTABLE_REASON.CODE_NOT_IN_MAX_ALLOWABLE_BILLED,
      text: 'Diagnosis code not included in the max allowable billed codes',
      value: CONDITION.REPORTABLE_REASON.CODE_NOT_IN_MAX_ALLOWABLE_BILLED
    },
    {
      key: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_ADD_DX,
      text: 'Coder recommended ADD DX code; provider did not respond',
      value: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_ADD_DX
    },
    {
      key: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_DELETE_DX,
      text: 'Coder recommended DELETE DX code; provider did not respond',
      value: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_DELETE_DX
    },
    {
      key: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_MODIFY_DX,
      text: 'Coder recommended MODIFY DX code; provider did not respond',
      value: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_MODIFY_DX
    },
    {
      key: CONDITION.REPORTABLE_REASON.CODING_CONFLICT,
      text: 'Coding conflict',
      value: CONDITION.REPORTABLE_REASON.CODING_CONFLICT
    },
    {
      key: CONDITION.REPORTABLE_REASON.DOES_NOT_SATISFY_GUIDELINES,
      text: 'Does not satisfy MEAT/TAMPER guidelines',
      value: CONDITION.REPORTABLE_REASON.DOES_NOT_SATISFY_GUIDELINES
    },
    {
      key: CONDITION.REPORTABLE_REASON.MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED,
      text: 'More/Less specific diagnosis code documented than one presented',
      value: CONDITION.REPORTABLE_REASON.MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED
    },
    {
      key: CONDITION.REPORTABLE_REASON
        .PATIENT_REFUTES_DIAGNOSIS_NO_RECORD_TO_VALIDATE,
      text: 'Patient refutes diagnosis code and no medical record to validate',
      value:
        CONDITION.REPORTABLE_REASON
          .PATIENT_REFUTES_DIAGNOSIS_NO_RECORD_TO_VALIDATE
    },
    {
      key: CONDITION.REPORTABLE_REASON.OTHER,
      text: 'Other, please provide an explanation',
      value: CONDITION.REPORTABLE_REASON.OTHER
    },
    {
      key: CONDITION.REPORTABLE_REASON.ACUTE_VISIT,
      text: 'Acute visit thus provider did not address',
      value: CONDITION.REPORTABLE_REASON.ACUTE_VISIT
    },
    {
      key: CONDITION.REPORTABLE_REASON.CONDITION_REPORTED_RESOLVED,
      text: 'Condition reported as resolved',
      value: CONDITION.REPORTABLE_REASON.CONDITION_REPORTED_RESOLVED
    },
    {
      key: CONDITION.REPORTABLE_REASON.CONFLICTING_DOCUMENTATION,
      text: 'Conflicting documentation',
      value: CONDITION.REPORTABLE_REASON.CONFLICTING_DOCUMENTATION
    },
    {
      key: CONDITION.REPORTABLE_REASON.PROVIDER_DOCUMENTATION_SUPPORTS_CODE,
      text: 'Coder addition of diagnosis code with compliant provider documentation',
      value: CONDITION.REPORTABLE_REASON.PROVIDER_DOCUMENTATION_SUPPORTS_CODE
    },
    {
      key: CONDITION.REPORTABLE_REASON.DOES_NOT_MEET_GUIDELINES,
      text: 'Does not meet (MEAT/TAMPER) guidelines',
      value: CONDITION.REPORTABLE_REASON.DOES_NOT_MEET_GUIDELINES
    },
    {
      key: CONDITION.REPORTABLE_REASON.EXCLUDES_NOTES,
      text: 'Does not meet coding guidelines: Excludes notes',
      value: CONDITION.REPORTABLE_REASON.EXCLUDES_NOTES
    },
    {
      key: CONDITION.REPORTABLE_REASON.SIGNS_SYMPTOMS_AS_PDX,
      text: 'Does not satisfy coding guidelines: Signs/Symptoms as PDX',
      value: CONDITION.REPORTABLE_REASON.SIGNS_SYMPTOMS_AS_PDX
    },
    {
      key: CONDITION.REPORTABLE_REASON.SUSPECTED_VS_CONFIRMED,
      text: 'Does not satisfy coding guidelines: Suspected vs Confirmed',
      value: CONDITION.REPORTABLE_REASON.SUSPECTED_VS_CONFIRMED
    },
    {
      key: CONDITION.REPORTABLE_REASON.LESS_SPECIFIC_DX_DOCUMENTED,
      text: 'Less specific DX documented',
      value: CONDITION.REPORTABLE_REASON.LESS_SPECIFIC_DX_DOCUMENTED
    },
    {
      key: CONDITION.REPORTABLE_REASON.MORE_SPECIFIC_CONDITION_DOCUMENTED,
      text: 'More specific condition documented',
      value: CONDITION.REPORTABLE_REASON.MORE_SPECIFIC_CONDITION_DOCUMENTED
    },
    {
      key: CONDITION.REPORTABLE_REASON.NOT_DOCUMENTED_DURING_ENCOUNTER,
      text: 'Not documented during this encounter',
      value: CONDITION.REPORTABLE_REASON.NOT_DOCUMENTED_DURING_ENCOUNTER
    },
    {
      key: CONDITION.REPORTABLE_REASON.OTHER,
      text: 'Other, specify reason',
      value: CONDITION.REPORTABLE_REASON.OTHER
    }
  ]
}

/**
 * @return List of coder reportable reasons by selected header
 */
export function coderReportableReasonsByHeader(header: string) {
  if (
    header &&
    (header === CONDITION.REPORTABLE_HEADER.NOT_DOCUMENTED ||
      header === 'Not Documented')
  ) {
    return [
      {
        key: CONDITION.REPORTABLE_REASON.ACUTE_VISIT,
        text: 'Acute visit',
        value: CONDITION.REPORTABLE_REASON.ACUTE_VISIT
      },
      {
        key: CONDITION.REPORTABLE_REASON.CONDITION_NOT_DOCUMENTED_OR_ADDRESSED,
        text: 'Condition not documented or addressed',
        value: CONDITION.REPORTABLE_REASON.CONDITION_NOT_DOCUMENTED_OR_ADDRESSED
      },
      {
        key: CONDITION.REPORTABLE_REASON.CONDITION_REPORTED_RESOLVED,
        text: 'Condition reported as resolved',
        value: CONDITION.REPORTABLE_REASON.CONDITION_REPORTED_RESOLVED
      },
      {
        key: CONDITION.REPORTABLE_REASON.LACK_OF_HISTORICAL_DATA,
        text: 'Lack of historical data/medical records to validate condition',
        value: CONDITION.REPORTABLE_REASON.LACK_OF_HISTORICAL_DATA
      }
    ]
  }
  if (
    header &&
    (header === CONDITION.REPORTABLE_HEADER.CODING_DETAILS ||
      header === 'Coding Details')
  ) {
    return [
      {
        key: CONDITION.REPORTABLE_REASON.CODE_NOT_IN_MAX_ALLOWABLE_BILLED,
        text: 'Diagnosis code not included in the max allowable billed codes',
        value: CONDITION.REPORTABLE_REASON.CODE_NOT_IN_MAX_ALLOWABLE_BILLED
      },
      {
        key: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_ADD_DX,
        text: 'Coder recommended ADD DX code; provider did not respond',
        value: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_ADD_DX
      },
      {
        key: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_DELETE_DX,
        text: 'Coder recommended DELETE DX code; provider did not respond',
        value: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_DELETE_DX
      },
      {
        key: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_MODIFY_DX,
        text: 'Coder recommended MODIFY DX code; provider did not respond',
        value: CONDITION.REPORTABLE_REASON.CODER_RECOMMEND_MODIFY_DX
      },
      {
        key: CONDITION.REPORTABLE_REASON.CODING_CONFLICT,
        text: 'Coding conflict',
        value: CONDITION.REPORTABLE_REASON.CODING_CONFLICT
      },
      {
        key: CONDITION.REPORTABLE_REASON.DOES_NOT_SATISFY_GUIDELINES,
        text: 'Does not satisfy MEAT/TAMPER guidelines',
        value: CONDITION.REPORTABLE_REASON.DOES_NOT_SATISFY_GUIDELINES
      },
      {
        key: CONDITION.REPORTABLE_REASON.MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED,
        text: 'More/Less specific diagnosis code documented than one presented',
        value: CONDITION.REPORTABLE_REASON.MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED
      },
      {
        key: CONDITION.REPORTABLE_REASON
          .PATIENT_REFUTES_DIAGNOSIS_NO_RECORD_TO_VALIDATE,
        text: 'Patient refutes diagnosis code and no medical record to validate',
        value:
          CONDITION.REPORTABLE_REASON
            .PATIENT_REFUTES_DIAGNOSIS_NO_RECORD_TO_VALIDATE
      }
    ]
  }
  if (
    header &&
    (header === CONDITION.REPORTABLE_HEADER.OTHER || header === 'Other')
  ) {
    return [
      {
        key: CONDITION.REPORTABLE_REASON.OTHER,
        text: 'Other, please provide an explanation',
        value: CONDITION.REPORTABLE_REASON.OTHER
      }
    ]
  }
  return coderReportableReasons()
}

/**
 * @return coderReportableStatuses in an OBJECT with the key = CONDITION.REPORTABLE_STATUS.XXX and
 *          value is the corresponding object from the original array of objects (key-value interpolation)
 */
export const coderReportableStatusKeyValueMap =
  CODER_REPORTABLE_STATUSES.reduce(
    (statuses, currEl) => ({ ...statuses, [currEl.key]: currEl }),
    {}
  )

export const coderTagsDropdownValidation = (
  coderTags: EncounterConditionCoderTagsRow
): boolean => {
  const isOther =
    coderTags.reportableHeaderCode === CONDITION.REPORTABLE_HEADER.OTHER
  // const hasReportableReason = !isNil(coderTags.reportableReason)
  const isEmptyString = (x: string | undefined | null) =>
    isNil(x) ? true : x.trim() === ''
  return (
    coderTags &&
    (!isOther || (isOther && isEmptyString(coderTags.reportableText)))
  )
}

export function coderWorklistEndHours(currentUser: User): number {
  const propKey = TENANT_PROPERTY.WORKLIST_CODER_QUERY_END_HOURS
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? parseInt(propValue, 10) : CODER.WORKLIST_QUERY_END_HOURS
}

/**
 * @return coderReportableReasons in an OBJECT with the key = CONDITION.REPORTABLE_REASON.XXX and
 *          value is the corresponding object from the original array of objects (key-value interpolation)
 *          FOR NEW REPORTABLE_REASONS ONLY (as of UI/backend update in March 2021 from [OR-2284] & [OR-2391])
 */
export const codingOptionsKeyValueMap = coderReportableReasons().reduce(
  (codingOpts, currEl) => ({ ...codingOpts, [currEl.key]: currEl }),
  {}
)

/**
 * 
 * @param ruleInputJson 
 * @returns 
 */
function hasCmsV28InternalRuleId(encounterConditionRuleRow: EncounterConditionRuleRow): boolean {
  const regex = /[^\d]+/g

  if (!!encounterConditionRuleRow.ruleInputJson && !!encounterConditionRuleRow.ruleInputJson.ruleInternalId) {
    const ruleInternalIdCode = encounterConditionRuleRow.ruleInputJson.ruleInternalId.replace(regex, '')
    if (!Number.isNaN(parseInt(ruleInternalIdCode, 10))) {
      const ruleInternalIdInt = parseInt(ruleInternalIdCode, 10)
      return ruleInternalIdInt >= 100 && ruleInternalIdInt <= 308 && encounterConditionRuleRow.targetType === "CLAIM" && encounterConditionRuleRow.ruleInputJson.conditionStatus === 'KNOWN'
    }
    return false
  }
  return false
}

/**
 * Logic for if during calendar year 2023 while we're "augmenting" CMS v24 with select CMS v28 conditions
 * it checks if the encounter condition has encounter condition rule with an internal ID pertaining
 * to the CMS v28 codes we want to augment for the time being
 * @param ruleRows 
 * @returns boolean if is an encounter condition surfaced via one of our CMS v24/v28 "hybrid" rules
 */
export function isCmsV28ConditionRule(conditionInput: EncounterConditionDisplay): boolean {
  return !!conditionInput.rules && !!conditionInput.rules[0] && !!conditionInput.rules[0].ruleInputJson &&
    hasCmsV28InternalRuleId(conditionInput.rules[0])
}

/**
 * Return true if condition is from billing data
 *
 * @param condition current condition
 * @returns TRUE if condition is from billing data
 */
export const isBillingData = (
  currentCondition: EncounterConditionDisplay
): boolean => {
  if (
    currentCondition &&
    currentCondition.rules &&
    currentCondition.rules[0] &&
    currentCondition.rules[0].ruleInputJson &&
    currentCondition.rules[0].ruleInputJson.claimUseEnum ===
      'EPIC_ENCOUNTER_DIAGNOSIS'
  ) {
    return true
  }
  return false
}

/**
 * @return Claim source for a condition, if exists
 */
export function conditionClaimSource(
  currentCondition: EncounterConditionDisplay
): string | null {
  if (
    currentCondition.rules &&
    currentCondition.rules[0] &&
    currentCondition.rules[0].targetSubtypeCode
  ) {
    const SUBTYPE_CODES = Object.values(RULE_TYPE_CODES)
    let ruleType = currentCondition.rules[0].targetSubtypeCode
    let claimSource = ''

    if (isBillingData(currentCondition)) {
      ruleType = 'CLAIM_BILLED'
    }

    if (
      ruleType.toUpperCase() !== 'MEDICATION' &&
      SUBTYPE_CODES.find(ruleTypeSubMap => ruleTypeSubMap.key === ruleType)
    ) {
      const ruleTypeSubMap = SUBTYPE_CODES.find(value => value.key === ruleType)
      claimSource = (ruleTypeSubMap && ruleTypeSubMap.displayName) as string
    }

    return claimSource
  }
  return null
}

/**
 * @param conditionType Condition group type enumeration
 * @return Corresponding condition group title for the provided type
 */
export function conditionGroupTitle(
  conditionType: keyof typeof CONDITION.TYPE
): string {
  if (conditionType === CONDITION.TYPE.ACTIVITY) {
    return 'Activities'
  }

  if (conditionType === CONDITION.TYPE.KNOWN) {
    return 'Known Conditions'
  }

  if (conditionType === CONDITION.TYPE.POTENTIAL) {
    return 'Potential Opportunities'
  }

  return ''
}

/**
 * Try to find the condition rule target subtype
 * @param condition Condition to check
 * @return Condition target subtype or null
 */
export function conditionTargetSubtype(
  currentCondition: EncounterConditionDisplay
) {
  if (
    currentCondition.rules &&
    currentCondition.rules[0] &&
    currentCondition.rules[0].targetSubtypeCode
  ) {
    return currentCondition.rules[0].targetSubtypeCode
  }
  return null
}

const shouldDisplayNLP = (currentUser: User): boolean =>
  !!encounterConditionDisplayNlpInsights(currentUser)

export function filterNlpConditions(
  currentUser: User,
  currentConditions: EncounterConditionDisplay[] | undefined
) {
  if (shouldDisplayNLP(currentUser)) return currentConditions
  return filter(currentConditions, currentCondition => {
    const ruleType = conditionTargetSubtype(currentCondition)
    if (ruleType && ruleType === RULE_TYPE_CODES.NLP_DOCUMENT.key) {
      return false
    }
    return true
  })
}

export function isSpecialtyOrTechOnly(
  currentCondition: EncounterConditionDisplay | undefined
): boolean {
    if (currentCondition && currentCondition.ruleFilterId &&
      RULE_FILTER_DYNAMIC_TYPES_BY_ID.hasOwnProperty(currentCondition.ruleFilterId)) {
      return true
    } else {
      return false
    }
}

/**
 * Filter a list of conditions on a specific status code with an option to
 * ignore conditions based on condition codes. For example: filter conditions
 * for status code ACTIVITY and ignore conditions with code: REMOVED. The final
 * list is sorted
 * @param role Role of the user calling this function
 * @param currentConditions List of conditions
 * @param conditionStatusCode Condition.conditionStatus to match
 * @param ignoreCodes List of Condition.conditionCode to ignore
 * @return List of filtered conditions
 */
export function filterWithStatus(
  role: string,
  currentConditions: EncounterConditionDisplay[] | undefined,
  conditionStatusCode: string,
  ignoreCodes: string[]
): EncounterConditionDisplay[] {
  return filter(currentConditions, currentCondition => {
    if ((currentCondition.statusCode === CONDITION.STATUS.HIDDEN) &&
      !(role === ROLES.CDI && isSpecialtyOrTechOnly(currentCondition))) {
      // never-ish show hidden conditions
      return false
    }
    if (currentCondition.conditionStatus === conditionStatusCode) {
      if (includes(ignoreCodes, currentCondition.conditionCode)) {
        return false
      }
      return true
    }
    return false
  })
}

/**
 *
 * @param coderTags coderTags object off of a condition entity
 * @returns either default option if no specific ICD10 code
 * specified in coderTags.conditionDiagnosisCode OR formatted template string
 * if a raw, de-JSON-ified ICD10 code exists
 * in coderTags.conditionDiagnosisCode and proper headers
 * (which should by default of UI be preset as such for NOT_ON_CLAIM and REPORTABLE) exist
 */
export function formatCoderAdditionOfIcd10Reason(
  coderTags: EncounterConditionCoderTagsRow
): string {
  if (
    coderTags &&
    coderTags.onClaimStatusCode === CONDITION.ON_CLAIM_STATUS.NOT_ON_CLAIM &&
    coderTags.reportableStatusCode === CONDITION.REPORTABLE_STATUS.REPORTABLE &&
    coderTags.conditionDiagnosisCode
  ) {
    return `Coder addition of diagnosis code "${formatIcd10Code(
      coderTags.conditionDiagnosisCode
    )}" with compliant provider documentation`
  }
  return 'Coder addition of diagnosis code with compliant provider documentation'
}

/**
 * Filter a list of conditions, returning addressed conditions
 * @param conditions List of condition detail entities
 * @return Filtered list of addressed conditions
 */
export function withAddressedConditions(
  currentConditions: EncounterConditionDisplay[]
): EncounterConditionDisplay[] {
  return filter(currentConditions, c => condition.isConditionAddressed(c))
}

/**
 * Filter a list of conditions, returning unaddressed conditions
 * @param conditions List of condition detail entities
 * @return Filtered list of unaddressed conditions
 */
export function withNotAddressedConditions(
  currentConditions: EncounterConditionDisplay[]
) {
  return filter(currentConditions, c => !condition.isConditionAddressed(c))
}

/**
 * Transform a list of conditions into a list of groups with two members: a
 * group of addressed conditions and a group of unaddressed conditions
 *
 * The grouping object matches the properties of the HCC grouping objects
 * See also hccUtils
 *
 * @param List of conditions
 * @return Conditions grouped into addressed / not-addressed buckets
 */
export function groupByAddressed(
  currentConditions: EncounterConditionDisplay[]
) {
  const notAddressed = withNotAddressedConditions(currentConditions)
  const addressed = withAddressedConditions(currentConditions)
  return [
    {
      hccCode: null,
      hccDescription: null,
      conditions: notAddressed,
      addressed: false
    },
    {
      hccCode: null,
      hccDescription: null,
      conditions: addressed,
      addressed: true
    }
  ]
}

export function hasClosedActivity(
  currentConditions: EncounterConditionDisplay[]
): boolean {
  return (
    currentConditions &&
    currentConditions.filter(c => condition.isClosedActivity(c)).length > 0
  )
}

/**
 * @param condition Condition entity
 * @return True if condition has note marked as coder query
 */
export function hasCoderQueryNote(
  currentCondition: EncounterConditionDisplay
): false | EncounterNoteRow | undefined {
  if (currentCondition.notes) {
    return currentCondition.notes.find(
      n => n.noteReasonCode && n.noteReasonCode === NOTE.REASON.CODER_QUERY
    )
  }
  return false
}

/**
 * @param displayConditions List of a displayConditions group for an encounter condition component
 * @param userRole Current role of user view, i.e. "CDI", "CODER", "PHYSICIAN"
 * @return TRUE if the list size of conditions/activities > 0
 */
export const hasConditionsOrActivities = (
  displayConditions: any,
  userRole: keyof typeof ROLES
): boolean => {
  switch (userRole) {
    case ROLES.CLINICAL_ADMIN:
    case ROLES.PHYSICIAN:
      // displayConditions are typically nested/rolled-up in the clinical admin & physician views
      return (
        displayConditions.filter(
          (displayElement: any) =>
            displayElement.conditions && displayElement.conditions.length > 0
        ).length > 0
      )
    case ROLES.CDI:
    case ROLES.CDI_QA_MANAGER:
    case ROLES.CODER:
    case ROLES.CODER_QA_MANAGER:
      return displayConditions.length > 0
    default:
      return false
  }
}

/**
 * @param condition Condition entity
 * @param expireHours Hours until query expiration. Default: 24
 * @return True if condition has query greater than time limit
 */
export function hasExpiredQuery(
  currentCondition: EncounterConditionDisplay,
  expireHours: number
) {
  const coderTags = currentCondition.coderTags
    ? currentCondition.coderTags
    : null
  if (
    condition.hasQuery(currentCondition) &&
    coderTags &&
    coderTags.queryCreationDate
  ) {
    return isExpired(coderTags.queryCreationDate, expireHours)
  }
  return false
}

export function hasExternal(
  currentConditions: EncounterConditionDisplay[]
): boolean {
  return (
    currentConditions &&
    currentConditions.filter(c => condition.isExternal(c)).length > 0
  )
}

export function hasKnown(
  currentConditions: EncounterConditionDisplay[]
): boolean {
  return (
    currentConditions &&
    currentConditions.filter(c => condition.isKnown(c)).length > 0
  )
}

export function hasKnownAddressed(
  currentConditions: EncounterConditionDisplay[]
): boolean {
  return (
    currentConditions &&
    currentConditions.filter(c => condition.isKnownAddressed(c)).length > 0
  )
}

export function hasPotential(
  currentConditions: EncounterConditionDisplay[]
): boolean {
  return (
    currentConditions &&
    currentConditions.filter(c => condition.isPotential(c)).length > 0
  )
}

/**
 * Order a list of conditions by HCC confidence, descending, nulls last
 * @param conditions List of conditions
 * @return Ordered list of conditions
 */
export function orderConditionsByHccConfidenceDescending(
  currentConditions: EncounterConditionDisplay[]
) {
  const sort = (item: any) =>
    item.confidence ? item.confidence.hccConfidence || '' : ''
  return orderBy(currentConditions, sort, 'desc')
}

/**
 * Splice a condition into a list of conditions, replacing existing
 * @param condition Condition to splice and replace
 * @param conditions List of conditions
 * @return Conditions
 */
export function spliceCondition(
  currentCondition: EncounterConditionDisplay,
  currentConditions: EncounterConditionDisplay[]
): EncounterConditionDisplay[] {
  return currentConditions.map(c =>
    c.id === currentCondition.id ? currentCondition : c)
}

/**
 * Splice multiple conditions into a list of conditions
 * @param updateConditions List of source conditions for splicing
 * @param currentConditions List of current conditions to update
 * @return Conditions
 */
export function spliceConditions(
  updateConditions: EncounterConditionDisplay[],
  currentConditions: EncounterConditionDisplay[]
): EncounterConditionDisplay[] {
  return currentConditions.map(c => {
    const replacement = updateConditions.find(u => u.id === c.id)
    return replacement || c
  })
}

/**
 * @return coderReportableHeaders in an OBJECT with the key = CONDITION.REPORTABLE_HEADER.XXX and
 * value is the corresponding object from the original array of objects (key-value interpolation)
 */
export const headerKeyValueMap = coderReportableHeaders.reduce(
  (headerOpts, currEl) => ({ ...headerOpts, [currEl.key]: currEl }),
  {}
)

export const showCoderReportableDiagnosisCodeEdit = (
  reportableReason: keyof typeof CONDITION.REPORTABLE_REASON
): boolean =>
  reportableReason === CONDITION.REPORTABLE_REASON.SIGNS_SYMPTOMS_AS_PDX ||
  reportableReason ===
    CONDITION.REPORTABLE_REASON.LESS_SPECIFIC_DX_DOCUMENTED ||
  reportableReason ===
    CONDITION.REPORTABLE_REASON.MORE_SPECIFIC_CONDITION_DOCUMENTED ||
  // New trigger as of UI update in March 2021 [OR-2284] && [OR-2391]
  reportableReason ===
    CONDITION.REPORTABLE_REASON.MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED

export const showNotReportableDiagnosisCodeEdit = (
  currentCondition: EncounterConditionDisplay
): boolean => {
  if (!currentCondition.coderTags) return false
  return (
    condition.isKnownOrPotential(currentCondition) &&
    condition.isOpenOrAddressed(currentCondition) &&
    currentCondition.coderTags &&
    (currentCondition.coderTags.reportableReason ===
      CONDITION.REPORTABLE_REASON.SIGNS_SYMPTOMS_AS_PDX ||
      currentCondition.coderTags.reportableReason ===
        CONDITION.REPORTABLE_REASON.LESS_SPECIFIC_DX_DOCUMENTED ||
      currentCondition.coderTags.reportableReason ===
        CONDITION.REPORTABLE_REASON.MORE_SPECIFIC_CONDITION_DOCUMENTED ||
      // New trigger as of UI update in March 2021 [OR-2284] && [OR-2391]
      currentCondition.coderTags.reportableReason ===
        CONDITION.REPORTABLE_REASON.MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED)
  )
}

/**
 * Filter a list of activities/quality measures, returning closed
 * activities/quality measures
 * @param conditions List of condition detail entities
 * @return Filtered list of closed activities/quality measures
 */
export function withClosedActivities(
  currentConditions: EncounterConditionDisplay[]
): EncounterConditionDisplay[] {
  return filter(currentConditions, c => condition.isClosedActivity(c))
}

/**
 * Guarantees that all conditions have encounter property set
 * @param conditions List of conditions
 * @param encounter Encounter that owns the conditions
 * @return Conditions
 */
export function withEncounter(
  currentConditions: EncounterConditionDisplay[],
  encounter: Encounter
): EncounterConditionDisplay[] {
  return currentConditions.map(c => ({
    ...c,
    encounter: {
      id: encounter.id as string,
      startDate: encounter.start
    }
  }))
}

/**
 * @param conditions List of conditions
 * @return List of conditions with outstanding coder query
 */
export function withOutstandingQuery(
  currentConditions: EncounterConditionDisplay[]
) {
  return currentConditions.filter(
    c =>
      c.coderTags &&
      c.coderTags.queryStatusCode === CONDITION.QUERY_STATUS.QUERY_OUTSTANDING
  )
}

/**
 * Filter conditions that show evidence of the provided rule type. The condition
 * must have a rule and target subtype and that subtype must match the rule type
 * @param conditions Conditions to filter
 * @param ruleType Rule type to match
 * @return All conditions matching the rule type
 */
export function withRuleType(
  currentConditions: EncounterConditionDisplay[],
  ruleType: string
): EncounterConditionDisplay[] {
  return currentConditions.filter(c => {
    const targetSubtypeCode = conditionTargetSubtype(c)
    return targetSubtypeCode && targetSubtypeCode === ruleType
  })
}

/**
 * Filter conditions returning conditions that do not match the provided rule
 * type. Note that a condition may not have a rule or rule target subtype. This
 * filter is explicitly looking for conditions with target subtype, otherwise
 * the condition passes the filter
 * @param conditions Conditions to filter
 * @param ruleType Rule type to match
 * @return All conditions not matching the rule type
 */
export function withoutRuleType(
  currentConditions: EncounterConditionDisplay[],
  ruleType: string
): EncounterConditionDisplay[] {
  return currentConditions.filter(c => {
    const targetSubtypeCode = conditionTargetSubtype(c)
    if (targetSubtypeCode) {
      return targetSubtypeCode !== ruleType
    }
    return true
  })
}
