/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Icon, Modal, Segment, Popup } from 'semantic-ui-react'
import concat from 'lodash/concat'
import { addDays, addMinutes, format, isAfter, isBefore, startOfDay, subMinutes } from 'date-fns'
import { API_URL, ENCOUNTER, FORMAT, LIST_MAX_PAGES, LIST_PAGE_SIZE, PATIENT, REVIEW } from '../../../constant'
import { api } from '../../../action/api'
import { searchGenerator } from '../../../action/search'
import { updateUserWorklistFilters } from '../../../action/user'
import { DatePickerIcon } from '../../common/datePickerIcon'
import { patientMrn } from '../../common/patientUtils'
import PdfHuddleReport from '../../common/pdfHuddleReport'
import PractitionerSelect from '../../common/practitionerSelect'
import SubHeader from '../../common/subHeader'
import Table from '../../common/table'
import './clinicalAdminWorklist.css'
import { semiColonsToLineBreaks } from '../../v2/utils/utils'

/**
 * Provides a worklist view of encounters for the Clinical Admin user role
 *
 * @param onSelect Callback function to execute when an encounter is selected
 *        arguments: Encounter ID
 */
class ClinicalAdminWorklist extends Component {

  constructor(props) {
    super(props)

    this.onPractitionerSelect = this.onPractitionerSelect.bind(this)
    const userFilters = this.props.currentUser.worklistFilters
    const viewDate = userFilters.start ? startOfDay(new Date(userFilters.start)) : startOfDay(new Date())
    const practitionerId = userFilters.view ? userFilters.view : null

    this.state = {
      viewDate,
      encounters: [],
      practitionerId,
      pagesDisplayed: 0,
      encounterSort: 'start asc'
    }
  }

  componentDidMount() {
    const offset = 0
    const start = this.state.viewDate
    const {practitionerId} = this.state
    this.fetchEncounters(start, practitionerId, offset)
  }

  onPractitionerSelect(e, { name, value }) {
    const offset = 0
    const {viewDate} = this.state
    const practitionerId = value === '' || value === null ? null : value
    const userFilters = this.props.currentUser.worklistFilters
    userFilters.view = practitionerId
    this.props.updateUserWorklistFilters(this.props.currentUser, userFilters)
    this.fetchEncounters(viewDate, practitionerId, offset)
  }

  incrementViewDate = () => {
    this.changeDay(addDays(this.state.viewDate, 1))
  }

  decrementViewDate = () => {
    this.changeDay(addDays(this.state.viewDate, -1))
  }

  onDateSelect = value => {
    this.changeDay(value)
  }

  onMore = () => {
    const {viewDate} = this.state
    const practitioner = this.state.practitionerId
    const pages = this.state.pagesDisplayed
    const offset = LIST_PAGE_SIZE * pages + 1
    this.fetchEncounters(viewDate, practitioner, offset)
  }

  selectRecord = (id, event) => {
    if (this.props.onSelect && id) {
      this.props.onSelect(id, event)
    }
  }

  changeDay(newDate) {
    const offset = 0
    const start = startOfDay(new Date(newDate))
    const userFilters = this.props.currentUser.worklistFilters
    userFilters.start = start
    this.props.updateUserWorklistFilters(this.props.currentUser, userFilters)
    this.fetchEncounters(start, this.state.practitionerId, offset)
  }

  generateTableData(list) {
    if (!list || list.length === 0) return []
    return list.map(record => {
      const {tenantId} = record.patient
      const time = format(record.start, FORMAT.TIME)
      const name = `${record.patient.firstName  } ${  record.patient.lastName}`
      const mrn = patientMrn(record.patient, tenantId)
      const nameDob = this.mobilePatientName(record.patient)
      const practitioner = `${record.practitioner.firstName  } ${  record.practitioner.lastName}`
      return {
        id: record.id,
        time,
        name,
        dob: format(record.patient.birthDate, FORMAT.DATE),
        mrn: semiColonsToLineBreaks(mrn),
        nameDob,
        practitioner,
        meta: {
          className: this.rowClassName(record.start)
        }
      }
    })
  }

  rowClassName(startDate) {
    const baseClass = 'cursor-pointer'
    const {viewDate} = this.state
    const onSchedule = viewDate.getTime() === startOfDay(new Date()).getTime()
    const encounterRange = 30

    if (!onSchedule) {
      return baseClass
    }

    const lowerLimit = subMinutes(new Date(), encounterRange)
    const upperLimit = addMinutes(new Date(), encounterRange)

    if (isBefore(startDate, lowerLimit)) {
      return `${baseClass  } past`
    } if (isAfter(startDate, upperLimit)) {
      return `${baseClass  } future`
    }
    return `${baseClass  } current`

  }

  mobilePatientName(patient) {
    return `${patient.firstName  } ${  patient.lastName
    } ( ${  format(patient.birthDate, FORMAT.DATE)  } ) `
  }

  dateTitle() {
    const date = this.state.viewDate

    if (date.getTime() === startOfDay(new Date()).getTime())
      return 'Today\'s Patients'
    if (date.getTime() === addDays(startOfDay(new Date()), 1).getTime())
      return 'Tomorrow\'s Patients'
    if (date.getTime() === addDays(startOfDay(new Date()), -1).getTime())
      return 'Yesterday\'s Patients'
    if (date.getTime() < new Date())
      return `Patients from ${  format(date, FORMAT.DAY_DATE)}`
    return `Patients on ${  format(date, FORMAT.DAY_DATE)}`
  }

  fetchEncounters(viewDate, practitionerId, offset) {
    const {currentUser} = this.props
    const searchQuery = {
      start: viewDate,
      patientStatus: PATIENT.STATUS.ACTIVE,
      reviewCode: [
        REVIEW.CODE.READY_FOR_PHYSICIAN,
        REVIEW.CODE.READY_FOR_CODER
      ],
      encounterStatus: [
        ENCOUNTER.STATUS.ARRIVED,
        ENCOUNTER.STATUS.FINISHED,
        ENCOUNTER.STATUS.IN_PROGRESS,
        ENCOUNTER.STATUS.PLANNED
      ]
    }

    if (practitionerId) {
      searchQuery.practitionerId = practitionerId
    }

    if (!searchQuery.patientRegion) {
      const defaultRegions = currentUser.worklistFilters.defaultPatientRegion
      if (defaultRegions) {
        searchQuery.patientRegion = defaultRegions
      }
    }

    if (!searchQuery.patientOrgId) {
      const defaultOrgId = currentUser.worklistFilters.defaultPatientOrgId
      if (defaultOrgId) {
        searchQuery.patientOrgId = defaultOrgId
      }
    }

    const sort = this.state.encounterSort
    const url = API_URL.ENCOUNTER_SEARCH
    const limit = offset === 0 ? LIST_PAGE_SIZE + 1 : LIST_PAGE_SIZE
    const params = searchGenerator(searchQuery, limit, offset, sort)

    api.get(url, { params }).then(({ data }) => {
      const results = offset === 0 ? data : concat(this.state.encounters, data)
      const pages = offset === 0 ? 1 : this.state.pagesDisplayed + 1
      this.setState({
        viewDate,
        encounters: results,
        practitionerId,
        pagesDisplayed: pages
      })
    })
  }

  leftSubHeader() {
    return this.dateTitle()
  }

  rightSubHeader() {
    const encounterIds = this.state.encounters.map(item => item.id)
    const region = this.props.currentUser.worklistFilters.defaultPatientRegion
    const orgId = this.props.currentUser.worklistFilters.defaultPatientOrgId
    return (
      <Grid>
        <Grid.Row style={ { paddingBottom: '0rem' } }>
          <Grid.Column floated="right" align="right">
            <span onClick={ this.decrementViewDate }>
              <Popup content="Previous Day" mouseEnterDelay={ 1000 }
                trigger={
                  <Icon
                    name="arrow alternate circle left outline"
                    size="large" color="grey" className="pointer" /> } />
            </span>
            <span>
              <DatePickerIcon onSelect={ this.onDateSelect.bind(this) }
                popperClassName="datePickerIcon-popper-left-adjust" />
            </span>
            <span onClick={ this.incrementViewDate } >
              <Popup content="Next Day" mouseEnterDelay={ 1000 }
                trigger={
                  <Icon
                    name="arrow alternate circle right outline"
                    size="large" color="grey" className="pointer" /> } />
            </span>
            { encounterIds.length === 0 && (
              <span>
                <Icon size="large" className="empty" />
              </span>
            ) }
            { encounterIds.length > 0 && (
              <span>
                <Popup content="Daily Huddle PDF" mouseEnterDelay={ 1000 }
                  trigger={
                    <Modal
                      size="fullscreen"
                      trigger={ <Icon name="print" size="large" color="grey"
                        className="pointer" /> }>
                      <Modal.Content>
                        <PdfHuddleReport startDt={ this.state.viewDate }
                          practitionerId={ this.state.practitionerId }
                          region={ region } orgId={ orgId } />
                      </Modal.Content>
                    </Modal>
                  } />
              </span>
            ) }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  render() {
    const tableData = this.generateTableData(this.state.encounters)
    const {practitionerId} = this.state
    const orgId = this.props.currentUser.worklistFilters.defaultPatientOrgId
    const pages = this.state.pagesDisplayed
    let displayMore = false
    let displayMaxRecords = false

    if (tableData.length > pages * LIST_PAGE_SIZE) {
      displayMore = pages < LIST_MAX_PAGES
      displayMaxRecords = pages >= LIST_MAX_PAGES
      tableData.pop()
    }

    return (
      <div className="header-padding">
        <Container>
          <SubHeader left={ this.leftSubHeader() } right={ this.rightSubHeader() } />
          <Segment attached className="no-margin">
            <Grid>
              <Grid.Row>
                <Grid.Column floated="right" align="right"
                  mobile={ 16 } tablet={ 16 } computer={ 16 }>
                  <PractitionerSelect value={ practitionerId } allOption={ true }
                    orgId={ orgId } onChange={ this.onPractitionerSelect } />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
                  <Table
                    computerColumns={ [
                      { name: 'Time', value: 'time' },
                      { name: 'Name', value: 'name' },
                      { name: 'DOB', value: 'dob' },
                      { name: 'MRN(s)', value: 'mrn' },
                      { name: 'Practitioner', value: 'practitioner' }
                    ] }
                    mobileColumns={ [
                      { value: 'time', width: '4' },
                      { value: 'nameDob', width: '12' }
                    ] }
                    data={ tableData }
                    onClick={ this.selectRecord }
                    more={ displayMore }
                    max={ displayMaxRecords }
                    onMore={ this.onMore } />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), { updateUserWorklistFilters }
)(ClinicalAdminWorklist)
